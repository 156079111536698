import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import SideNav from './components/sideNav'
import Header from './components/header'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
//import { Router, Route, IndexRoute, useRouterHistory } from 'react-router';
//import { createHashHistory } from 'history';
import { AuthProvider } from './context/AuthProvider'

//window.SERVER_APP_API = 'http://localhost:3001'
//window.SERVER_APP_API_PHP = 'http://localhost/react/cdf/src/server'
//window.SERVER_APP_API_PHP = 'http://localhost:8080/react/cdf/src/server'
//window.SERVER_APP_API_PHP = 'https://sims.app.kanchinnotech.nl'
//window.SERVER_APP_API = 'https://sims.app.kanchinnotech.nl'

//window.SERVER_APP_API_PHP = 'https://cdfserver.kanchinnotech.nl'
window.SERVER_APP_API_PHP = 'https://cdftestserver.kanchinnotech.nl'

const root = ReactDOM.createRoot(document.getElementById('root'))
//const header = ReactDOM.createRoot(document.getElementById('header'));
//const sideNav = ReactDOM.createRoot(document.getElementById('sideNav'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
)

/*header.render(
  <React.StrictMode>
    <Header />
  </React.StrictMode>
)*/

/*sideNav.render(
  <React.StrictMode>
    <SideNav />
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
